import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from "../components/Common/PageBanner"
import { graphql, useStaticQuery } from 'gatsby'

const ThankYou = () => {
    const data = useStaticQuery(graphql`
        query ThankYouQuery {
            site {
                siteMetadata {
                    title
                    description
                    name
                    email
                    phone
                }
            }
        }
    `);
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Thank You" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Thank You" 
            />
            <section className="contact-area pb-100">
                <div className="container">
                    <div>
                        <h3>Successfully Submitted</h3>
                        <p>Your request has been received.  Thank you for your interest in Smarter Spreadsheets. We will look over your request and get back to you as soon as possible. If you have any other questions, feel free to reach out via <a href={`mailto:${data.site.siteMetadata.email}`}>{data.site.siteMetadata.email}</a> or call us at <a href={`tel:+${data.site.siteMetadata.phone}`}>{data.site.siteMetadata.phone}</a></p>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default ThankYou